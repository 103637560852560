import { Descriptions } from "antd";

import styles from './Page.module.css';

function Tab2() {
  return (
    <div className={styles.tab}>
      <div className={styles.descriptions}>
        <Descriptions
          title={<div className={styles.title}>工作职责</div>}
          column={1}
          labelStyle={{ width: '7rem', fontWeight: 500 }}
          bordered
        >
          <Descriptions.Item label="镇路长">镇路长为区域内镇级农村公路（乡道、村道）建设养护管理和道路保洁垃圾整治等路域环境整治的第一责任人，承担落实总路长、副总路长、区级路长及区级路长办公室下达的各项任务。</Descriptions.Item>
          <Descriptions.Item label="镇分路长">负责协助镇路长推进相关工作任务。</Descriptions.Item>
          <Descriptions.Item label="村级路长">协助镇路长、镇分路长开展本村域内“路长制”各项工作。</Descriptions.Item>
          <Descriptions.Item label="专管员">专管员在镇级路长办公室的指导、监督下，具体负责镇级农村公路的日常路况巡查、病害隐患排查和道路保洁垃圾整治等路域环境巡查，参与管养单位监督考核、工程管理，及时制止并报告侵害路产路权行为，协助农村公路执法部门现场执法和涉路纠纷调处等工作。</Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
}

export default Tab2;