import { Tabs, Result } from 'antd';
import { InfoCircleOutlined, UserOutlined, CommentOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import styles from './Page.module.css';
import Tab1 from './Page.Tab1';
import Tab2 from './Page.Tab2';
import Tab3 from './Page.Tab3';
import { DATA } from './data';

function Page() {
  const { id } = useParams();
  const index = Number(id);
  if (Number.isInteger(index) && index < DATA.length) {
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.primary}>{DATA[index][0]}</div>
          <div className={styles.secondary}>路长制责任公示牌</div>
        </div>
        <div className={styles.body}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            items={[{
              key: '1',
              label: (
                <span>
                  <InfoCircleOutlined style={{ marginRight: '.25rem' }} />
                  道路信息
                </span>
              ),
              children: <Tab1 data={DATA[index]} />
            }, {
              key: '2',
              label: (
                <span>
                  <UserOutlined style={{ marginRight: '.25rem' }} />
                  工作职责
                </span>
              ),
              children: <Tab2 data={DATA[index]}  />
            }, {
              key: '3',
              label: (
                <span>
                  <CommentOutlined style={{ marginRight: '.25rem' }} />
                  出谋划策
                </span>
              ),
              children: <Tab3 data={DATA[index]} />
            }]}
            centered
          />
        </div>
        <div className={styles.footer}>
          <img className={styles.thumb} src="/footer@2x.png" alt="爱路护路，村规民约" />
          <div>上海市 闵行区 七宝镇<br />城市建设管理事务中心</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.root}>
        <Result status="warning" title="出错了" subTitle="请扫描正确的二维码" />
        <div className={styles.footer}>
          <img className={styles.thumb} src="/footer@2x.png" alt="爱路护路，村规民约" />
          <div>上海市 闵行区 七宝镇<br />城市建设管理事务中心</div>
        </div>
      </div>
    );
  }
}

export default Page;