import { Route, Routes, Navigate } from 'react-router-dom';
import Page from './Page';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/1" />} />
      <Route path="/:id" element={<Page />} />
    </Routes>
  );
}

export default App;