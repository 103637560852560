export const DATA = [
  [],
  ['华中路','二级','镇管','七莘路','中春路','≈1.137公里','吴昌飞','于翔','张展翅','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['华茂路','二级','镇管','七莘路','中春路','≈1.189公里','吴昌飞','于翔','沈春军','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['联明路','二级','镇管','七莘路','中春路','≈1.102公里','吴昌飞','于翔','沈春军','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['吴宝路','二级','镇管','吴中路','漕宝路','≈0.77公里','吴昌飞','于翔','王晓鸣','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','0','0','柏油'],
  ['中谊路','二级','镇管','新镇路','七莘路','≈0.71公里','吴昌飞','于翔','陈利敏','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['中谊路','二级','镇管','中春路','联宝路','≈0.574公里','吴昌飞','于翔','陈利敏','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','0','1','柏油'],
  ['星站路','三级','镇管','七莘路','华宝路','≈1.316公里','吴昌飞','于翔','施文斌','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['新龙路','三级','镇管','横泾港桥','华星港桥','≈1.06公里','吴昌飞','于翔','王晓鸣','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['沪星路','三级','镇管','七莘路','中春路','≈1公里','吴昌飞','于翔','施文斌','李震华','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['联宝路','三级','镇管','小涞港桥','中谊路','≈0.318公里','吴昌飞','于翔','陈利敏','胡钔','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['华宝路','四级','镇管','沪青平高架','星站路','≈0.871公里','吴昌飞','于翔','施文斌','胡钔','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['华友路','二级','镇管','华星港桥','中春路','≈0.454公里','吴昌飞','于翔','张展翅','胡钔','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','1','0','柏油'],
  ['宝盛路','四级','镇管','富强路','宝南路','≈0.168公里','吴昌飞','于翔','吴  钢','胡钔','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','0','0','柏油'],
  ['华林路','二级','镇管','华友路','华中路','≈0.313公里','吴昌飞','于翔','施文斌','胡钔','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','0','0','柏油'],
  ['联盛路','三级','镇管','联明路','华茂路','≈0.5公里','吴昌飞','于翔','陈利敏','胡钔','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','2','0','柏油'],
  ['星北路','三级','镇管','环东一路','虹莘路','≈0.91公里','吴昌飞','于翔','沈春军','胡钔','上海七一建筑工程有限公司','上海佳标市政交通工程工程有限公司','0','0','柏油']
];