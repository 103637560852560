import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";

import styles from './Page.module.css';

function Tab3() {
  return (
    <div className={styles.tab}>
      <div className={styles.descriptions}>
        <Form layout="vertical">
          <Form.Item label="建言献策" name="message" rules={[{ required: true, message: '请留下您的宝贵建议' }]}>
            <Input.TextArea placeholder="请留下您的宝贵建议" rows={3} />
          </Form.Item>
          <Form.Item label="相关图片" name="photos">
            <Upload><Button icon={<UploadOutlined />} type="primary" ghost>选择图片</Button></Upload>
          </Form.Item>
          <Form.Item label="姓名" name="name" rules={[{ required: true, message: '请填写您的姓名' }]}>
            <Input placeholder="请填写您的姓名" />
          </Form.Item>
          <Form.Item label="手机" name="mobile" rules={[{ required: true, message: '请填写您的手机' }]}>
            <Input type="tel" placeholder="请填写您的手机" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" block>立即提交</Button>
          </Form.Item>
        </Form>  
      </div>
    </div>
  );
}

export default Tab3;