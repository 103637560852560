import { Descriptions } from "antd";

import styles from './Page.module.css';

function Tab1({data}) {
  return (
    <div className={styles.tab}>
      <div className={styles.descriptions}>
        <Descriptions
          title={<div className={styles.title}>道路信息</div>}
          column={1}
          labelStyle={{ width: '9rem', fontWeight: 500 }}
          bordered
        >
          <Descriptions.Item label="道路名称">{data[0]}</Descriptions.Item>
          <Descriptions.Item label="道路等级">{data[1]}</Descriptions.Item>
          <Descriptions.Item label="管辖">{data[2]}</Descriptions.Item>
          <Descriptions.Item label="起点">{data[3]}</Descriptions.Item>
          <Descriptions.Item label="讫点">{data[4]}</Descriptions.Item>
          <Descriptions.Item label="道路长度">{data[5]}</Descriptions.Item>
          <Descriptions.Item label="镇路段长">{data[6]}</Descriptions.Item>
          <Descriptions.Item label="镇分路长">{data[7]}</Descriptions.Item>
          <Descriptions.Item label="村级路长">{data[8]}</Descriptions.Item>
          <Descriptions.Item label="专管员">{data[9]}</Descriptions.Item>
          <Descriptions.Item label="路面养护单位">{data[10]}</Descriptions.Item>
          <Descriptions.Item label="道路设施养护单位">{data[11]}</Descriptions.Item>
          <Descriptions.Item label="桥梁数量">{data[12]}</Descriptions.Item>
          <Descriptions.Item label="地道数量">{data[13]}</Descriptions.Item>
          <Descriptions.Item label="路面种类">{data[14]}</Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
}

export default Tab1;